import {Component, effect, input} from '@angular/core';
import {FlexLayoutModule, FlexModule} from "@ngbracket/ngx-layout";
import {MatIcon} from "@angular/material/icon";
import {MatToolbar} from "@angular/material/toolbar";
import {NgClass, NgIf} from "@angular/common";
import {MobileFooterSheetComponent} from "./mobile-footer-sheet/mobile-footer-sheet.component";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {NGXLogger} from "ngx-logger";
import {NativeService} from "../../_service/native.service";

@Component({
  selector: 'app-bottom',
  standalone: true,
  imports: [
    FlexModule,
    MatIcon,
    MatToolbar,
    NgIf,
    NgClass,
    FlexLayoutModule
  ],
  templateUrl: './bottom.component.html',
  styleUrl: './bottom.component.scss'
})
export class BottomComponent {
  currentUrl = input.required<string>();
  ios = false;
  activeMobileFooterIcon = '';
  hide: boolean = false;

  constructor(private readonly log: NGXLogger,
              private readonly nativeService: NativeService,
              private readonly matBottomSheet: MatBottomSheet) {

    this.ios = this.nativeService.isNativeiOS();

    effect(() => {
      this.checkMobileFooterIcon(this.currentUrl())

      this.hide = this.currentUrl().includes('/analysis/detail')
    })
  }

  openBottomSheet(type: string): void {
    let links: any[];

    if (type === 'ANALYSIS') {
      links = [
        {icon: 'monitor_heart', name: '권리분석 보고서', path: '/analysis/list', queryParams: {}},
        {icon: 'article', name: '신규 권리분석', path: '/analysis', queryParams: {}},
        {icon: 'bookmark_add', name: '쿠폰 구입', path: '/coupon', queryParams: {}},
        {icon: 'fmd_bad', name: '서비스 안내', path: '/analysis/info', queryParams: {}}
      ]
    } else if (type === 'HOUSE') {
      links = [
        {icon: 'notifications', name: '등기변경알림 부동산', path: '/house/list', queryParams: {}},
        {icon: 'add_location', name: '서비스 신청', path: '/house/register', queryParams: {type: 'MY_HOUSE'}},
        {icon: 'file_present', name: '일괄 신청', path: '/house/batch', queryParams: {}},
        {icon: 'format_list_numbered_rtl', name: '일괄 연장', path: '/house/extends', queryParams: {}},
        {icon: 'bookmark_add', name: '쿠폰 구입', path: '/coupon', queryParams: {}},
        {icon: 'fmd_bad', name: '서비스 안내', path: '/info/my-house', queryParams: {}}
      ]
    } else if (type === 'OPEN_HOUSE') {
      links = [
        {icon: 'notifications', name: '오픈 부동산 알림신청', path: '/house/open', queryParams: {}},
        {icon: 'edit_notifications', name: '오픈 부동산 등록', path: '/house/register', queryParams: {type: 'OPEN_HOUSE'}},
        {icon: 'format_list_numbered_rtl', name: '일괄 연장', path: '/house/extends', queryParams: {}},
        {icon: 'bookmark_add', name: '쿠폰 구입', path: '/coupon', queryParams: {}},
        {icon: 'fmd_bad', name: '서비스 안내', path: '/info/open-house', queryParams: {}},
      ]
    } else if (type === 'SERVICE') {
      links = [
        {icon: 'favorite', name: '이용금액', path: '/info/pricing', queryParams: {type: ''}},
        {icon: 'favorite', name: '자주하는 질문', path: '/info/faq', queryParams: {type: ''}},
        {icon: 'favorite', name: '이벤트', path: '/info/events', queryParams: {type: 'EVENT'}},
        {icon: 'error', name: '공지사항', path: '/info/notice', queryParams: {type: 'NOTICE'}},
        {icon: 'help_center', name: '1 : 1 문의', path: '/inquire', queryParams: {type: 'INQUIRE'}},
        {icon: 'pest_control', name: '오류신고', path: '/inquire', queryParams: {type: 'ERROR'}},
        {icon: 'warning', name: '기능요청', path: '/inquire', queryParams: {type: 'SUGGEST'}},
        {icon: 'nature_people', name: '회사소개', path: '/info/company', queryParams: {}},
      ]
    } else {
      links = [
        {icon: 'portrait', name: '마이 페이지', path: '/home', queryParams: {}},
        {icon: 'lock_open', name: '정보변경', path: '/home/info', queryParams: {}},
        {icon: 'bookmark_border', name: '쿠폰함', path: '/home/coupon', queryParams: {}},
        {icon: 'report_gmailerrorred', name: '결제내역', path: '/home/purchase', queryParams: {}},
        {icon: 'link', name: 'SNS 계정관리', path: '/home/sns', queryParams: {}},
        {icon: 'sentiment_very_dissatisfied', name: '로그아웃', logout: true}
      ]
    }

    links.forEach(link => link.type = type)

    this.matBottomSheet.open(MobileFooterSheetComponent, {data: {links}})
      .afterDismissed()
      .subscribe(response => {
        this.log.debug(response);
      })
  }

  private checkMobileFooterIcon(currentUrl: string) {
    if (currentUrl === '/house/list' || currentUrl.includes('/house/detail') || currentUrl.includes('/house/map') || currentUrl.includes('/house/statistics') || currentUrl.includes('/info/my-house')) {
      this.activeMobileFooterIcon = 'MY_HOUSE'
    } else if (currentUrl.includes('/house/open') || currentUrl.includes('OPEN_HOUSE') || currentUrl.includes('/info/open-house')) {
      this.activeMobileFooterIcon = 'OPEN_HOUSE'
    } else if (currentUrl.includes('/house/extends')) {
      this.activeMobileFooterIcon = ''
    } else if (currentUrl.includes('/house')) {
      this.activeMobileFooterIcon = 'HOUSE_REGISTER'
    } else if (currentUrl.includes('/home')) {
      this.activeMobileFooterIcon = 'HOME'
    } else if (currentUrl.includes('/analysis')) {
      this.activeMobileFooterIcon = 'ANALYSIS'
    } else if (currentUrl.includes('/info') || currentUrl.includes('/inquire')) {
      this.activeMobileFooterIcon = 'SERVICE'
    } else {
      this.activeMobileFooterIcon = ''
    }
  }
}
