<mat-toolbar [fxHide]="hide" [ngClass]="{'iphone-padding': ios}" class="mobile-bottom-toolbar mat-elevation-z3 p-v-33 c-gr-primary-mint">
  <div fxFlex fxLayout="row" fxLayoutAlign="space-between start">

    <div (click)="openBottomSheet('ANALYSIS')" class="cursor icon-text" fxFlex fxLayout="column"
         fxLayoutAlign="start center">
      <mat-icon [ngClass]="activeMobileFooterIcon === 'ANALYSIS' ? 'c-accent' : 'c-gray'">
        <span class="material-symbols-outlined xs-size f-500">mindfulness</span>
      </mat-icon>
      <div class="f-11 f-600">권리분석</div>
    </div>

    <div (click)="openBottomSheet('HOUSE')" class="cursor icon-text" fxFlex fxLayout="column"
         fxLayoutAlign="start center">
      <mat-icon [ngClass]="activeMobileFooterIcon === 'HOUSE_REGISTER' || activeMobileFooterIcon === 'MY_HOUSE' ? 'c-primary' : 'c-gray'">
        <span class="material-symbols-outlined xs-size f-500">circle_notifications</span>
      </mat-icon>
      <div class="f-11 f-600">등기변경알림</div>
    </div>

    <div (click)="openBottomSheet('OPEN_HOUSE')" class="cursor icon-text" fxFlex fxLayout="column"
         fxLayoutAlign="start center">
      <mat-icon [ngClass]="activeMobileFooterIcon === 'OPEN_HOUSE' ? 'c-primary' : 'c-gray'">
        <span class="material-symbols-outlined xs-size">tooltip</span>
      </mat-icon>
      <div class="f-11 f-600">오픈 부동산</div>
    </div>

    <div (click)="openBottomSheet('SERVICE')" class="cursor icon-text" fxFlex fxLayout="column"
         fxLayoutAlign="start center">
      <mat-icon [ngClass]="activeMobileFooterIcon === 'SERVICE' ? 'c-primary' : 'c-gray'">
        <span class="material-symbols-outlined xs-size f-400">web_stories</span>
      </mat-icon>
      <div class="f-11 f-600">더보기</div>
    </div>
  </div>
</mat-toolbar>
