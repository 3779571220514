import {Injectable} from "@angular/core";
import {NGXLogger} from "ngx-logger";
import {RestClientService} from "../../../../projects/shared/src/lib/rest-client.service";
import {map} from "rxjs/operators";
import {ToastService} from "../../../../projects/shared/src/lib/toast.service";
import {Realestate} from "../_view/realestate";

@Injectable()
export class AnalysisService {

  constructor(private readonly log: NGXLogger,
              private readonly restClient: RestClientService,
              private readonly toast: ToastService) {
  }

  public temporarySave(request: any) {
    return this.restClient.post('/analysis/temporary', request)
               .pipe(map((response) => {
                 return response;
               }));
  }

  public getCounts() {
    return this.restClient.get('/analysis/counts')
               .pipe(map((response) => {
                 return response.data;
               }));
  }

  public searchAnalysis(request: any) {
    return this.restClient.get('/analysis', request)
               .pipe(map((response) => {
                 return response.data;
               }));
  }

  public getAnalysis(analysisId: number) {
    return this.restClient.get(`/analysis/${analysisId}`)
               .pipe(map((response) => {
                 return response.data;
               }));
  }

  public getTargetRights(analysisId: number) {
    return this.restClient.get(`/analysis/${analysisId}/target-rights`)
               .pipe(map((response) => {
                 return response.data;
               }));
  }

  public getTargetRightsOptions(analysisId: number) {
    return this.restClient.get(`/analysis/${analysisId}/target-rights/options`)
               .pipe(map((response) => {
                 return response.data;
               }));
  }

  public changeTargetRights(analysisId: number, targetRights: any) {
    return this.restClient.patch(`/analysis/${analysisId}/target-rights`, targetRights)
               .pipe(map((response) => {
                 return response.data;
               }));
  }

  public getBuildingTitle(analysisId: number, realestateId: number) {
    return this.restClient.get(`/analysis/${analysisId}/realestates/${realestateId}/reports/title`)
               .pipe(map((response) => {
                 return response.data;
               }));
  }

  public changeName(analysisId: number, newName: string) {
    return this.restClient.patch(`/analysis/${analysisId}/name`, newName)
               .pipe(map((response) => {
                 if (response.success) {
                   this.toast.show('분석 이름이 변경되었습니다.')
                 }
                 return response.data;
               }));
  }

  public changeGroup(analysisId: number, newGroupIds: number[]) {
    return this.restClient.patch(`/analysis/${analysisId}/groups`, {ids: newGroupIds})
               .pipe(map((response) => {
                 if (response.success) {
                   this.toast.show('그룹이 변경되었습니다.')
                 }
                 return response.data;
               }));
  }

  public toggleBookmark(analysisId: number) {
    return this.restClient.patch(`/analysis/${analysisId}/bookmark`)
               .pipe(map((response) => {
                 const message = response.data ? '북마크 되었습니다.' : '북마크가 해제되었습니다.'
                 this.toast.show(message)
                 return response.data;
               }));
  }

  public updateCompleteStatus(analysisId: number) {
    return this.restClient.patch(`/analysis/${analysisId}/status/complete`)
               .pipe(map((response) => {
                 return response.data;
               }));
  }

  public updateSummary(analysisId: number) {
    return this.restClient.patch(`/analysis/${analysisId}/summary`)
               .pipe(map((response) => {
                 return response.data;
               }));
  }

  public deleteAnalysis(analysisId: number) {
    return this.restClient.delete(`/analysis/${analysisId}`)
               .pipe(map((response) => {
                 this.toast.show('권리분석이 삭제되었습니다.')
                 return response.data;
               }));
  }

  public getAllGroups() {
    return this.restClient.get('/analysis-group')
               .pipe(map((response) => {
                 return response.data;
               }));
  }

  public addGroup(groupName: string) {
    return this.restClient.post(`/analysis-group`, {name: groupName})
               .pipe(map((response) => {
                 return response.data;
               }));
  }

  public changeGroupName(groupId: number, groupName: string) {
    return this.restClient.patch(`/analysis-group`, {id: groupId, name: groupName})
               .pipe(map((response) => {
                 return response.data;
               }));
  }

  public deleteGroup(groupId: number) {
    return this.restClient.delete(`/analysis-group/${groupId}`)
               .pipe(map((response) => {
                 return response.data;
               }));
  }

  public getStandardPrices(realestate: Realestate) {
    return this.restClient.get(`/analysis/${realestate.analysisId}/realestates/${realestate.id}/prices`)
               .pipe(map((response) => {
                 return response.data;
               }));
  }

  public hasMarketPrices(realestate: Realestate, tradeType: string) {
    return this.restClient.get(`/analysis/${realestate.analysisId}/realestates/${realestate.id}/market-prices?type=${tradeType}`)
               .pipe(map((response) => {
                 return response.data;
               }));
  }

  public updateRealestatePrice(realestate: Realestate, priceId: number, request: any) {
    return this.restClient.patch(`/analysis/${realestate.analysisId}/realestates/${realestate.id}/prices/${priceId}`, request)
               .pipe(map((response) => {
                 return response.data;
               }));
  }

  checkValid(id: number) {
    return this.restClient.get(`/analysis/${id}/valid`)
               .pipe(map((response) => {
                 return response.data;
               }));
  }

  getAllInfo(id: number) {
    return this.restClient.get(`/analysis/${id}/rights`)
               .pipe(map((response) => {
                 return response.data;
               }));
  }
}
