import {Component, input} from '@angular/core';
import {ExtendedModule, FlexModule} from "@ngbracket/ngx-layout";
import {MatButton} from "@angular/material/button";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatToolbar} from "@angular/material/toolbar";
import {NgIf} from "@angular/common";
import {RouterLink} from "@angular/router";
import {NGXLogger} from "ngx-logger";
import {NotificationService} from "../../domain/notification/notification.service";
import {MatBadge} from "@angular/material/badge";
import {LoginDialogService} from "../../shared/dialog/login-dialog/login-dialog.service";
import {SessionStorageService} from "../../_service/session-storage.service";
import {UserService} from "../../domain/user/service/user.service";
import {NativeService} from "../../_service/native.service";

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    ExtendedModule,
    FlexModule,
    MatButton,
    MatMenu,
    MatMenuItem,
    MatToolbar,
    NgIf,
    RouterLink,
    MatMenuTrigger,
    MatBadge
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  mobile = input<boolean>(true);
  login = input<boolean>(false);
  countNotifications: number | undefined = undefined;

  constructor(private readonly log: NGXLogger,
              private readonly notificationService: NotificationService,
              private readonly loginDialogService: LoginDialogService,
              private readonly sessionStorage: SessionStorageService,
              private readonly userService: UserService,
              private readonly nativeService: NativeService) {
    this.notificationService.changeCount()
      .subscribe(count => {
        this.countNotifications = count || undefined;
      });
  }

  clickLogin() {
    this.log.debug('Click Login')
    this.loginDialogService.openLoginDialog();
  }

  clickLogout() {
    this.log.debug('Click Logout')
    this.userService.logout();
    this.sessionStorage.clear();
    this.nativeService.forgetMe();
  }
}
